import React from "react"
import Helmet from 'react-helmet';
import "./layout.css"

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <title>Eric O'Hanlon</title>
      </Helmet>
      <div>{children}</div>
    </>
  );
}
